<template>
  <div
    class="flex flex-col justify-center items-center w-full h-screen sm:px-2"
  >
    <div class="sm:px-7">
      <h2
        class="font-main font-bold text-3xl text-center md:text-xl lg:text-xl text-promy-dark-purple sm:leading-7"
      >
        Votre mot de passe a été réinitialisé avec succès
        <svg
          class="inline"
          width="25"
          height="25"
          viewBox="0 0 25 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 12.5C25 15.8152 23.683 18.9946 21.3388 21.3388C18.9946 23.683 15.8152 25 12.5 25C9.18479 25 6.00537 23.683 3.66117 21.3388C1.31696 18.9946 0 15.8152 0 12.5C0 9.18479 1.31696 6.00537 3.66117 3.66117C6.00537 1.31696 9.18479 0 12.5 0C15.8152 0 18.9946 1.31696 21.3388 3.66117C23.683 6.00537 25 9.18479 25 12.5ZM18.7969 7.76562C18.6853 7.6544 18.5524 7.56683 18.4061 7.50815C18.2599 7.44946 18.1033 7.42087 17.9458 7.42408C17.7883 7.42729 17.633 7.46223 17.4893 7.52683C17.3456 7.59142 17.2164 7.68433 17.1094 7.8L11.6828 14.7141L8.4125 11.4422C8.19035 11.2352 7.89653 11.1225 7.59293 11.1279C7.28934 11.1332 6.99967 11.2562 6.78497 11.4709C6.57026 11.6856 6.44727 11.9753 6.44191 12.2789C6.43656 12.5825 6.54925 12.8763 6.75625 13.0984L10.8906 17.2344C11.002 17.3456 11.1346 17.4332 11.2806 17.492C11.4266 17.5508 11.5829 17.5796 11.7402 17.5767C11.8976 17.5737 12.0527 17.5392 12.1964 17.475C12.3401 17.4108 12.4694 17.3184 12.5766 17.2031L18.8141 9.40625C19.0267 9.18515 19.1442 8.88949 19.1413 8.58275C19.1383 8.276 19.0153 7.98263 18.7984 7.76562H18.7969Z"
            fill="#34324F"
          />
        </svg>
      </h2>
      <pro-button
        class="rounded-lg mt-8 mx-auto px-10 !py-4 !sm:px-2"
        @click="NavigateLogin"
        btn="primary"
      >
        <span class="!sm:text-sm">Retournez à la page de connexion</span>
      </pro-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    NavigateLogin() {
      this.$router.push({ name: 'Login' })
    },
  },
}
</script>
